import React, { useState } from "react";
import { Link } from "gatsby"
import Layout from "../components/layout/Layout"
import Container from "../components/layout/Container"
import Type from "../components/typography/Type"
import { isLoggedIn } from "../services/auth"
import axios from "axios";
import { Formik, Form, Field, FieldArray, ErrorMessage } from "formik";
import * as Yup from "yup";
import LoginPrompt from "../components/app/loginPrompt"
import Input from "../components/forms/Input"
import Select from "../components/forms/Select"
import TextArea from "../components/forms/TextArea"
import Hidden from "../components/forms/Hidden"




const IndexPage = () => {
  
  
  
  return (
    <Layout>
    <Container>
    
    
    {isLoggedIn() ? (
      <div>
        <Type type="h2">Welcome to Framework</Type>
      </div> 
   ) : (
       <div>
         <LoginPrompt/>
       </div>     
     )}



   </Container>
    </Layout>
  )
}

export default IndexPage

export const Head = () => <title>Framework</title>

